<template>
  <div class="container-fluid">
    <div class="row">
      <div  class="col-12 errorContainer text-center">
        <ul style="list-style: none">
          <li v-for="errMsg in errMessages" :key="errMsg" >{{errMsg}}</li>
        </ul>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12 text-center">
        <label for="jobName">&nbsp;&nbsp;Job Name:&nbsp;&nbsp;&nbsp;&nbsp;</label>
        <input id="jobName" ref="jobName" v-model="job.name"  >
      </div>
    </div>
    <div v-show="jobs.length >0" class="row">
      <div class="col-12 text-center">
      <br>
      <h6>&nbsp;&nbsp; Or you can edit or delete existing jobs.. </h6>
      <br>
      </div>
    </div>

    <div class="row" v-show="jobs.length >0">
      <div class="col-12 text-center">

        <label for="jobs">Current Jobs:&nbsp;</label>
        <select id="jobs" ref="jobs" @change="switchJob">
          <option selected disabled value="{}">Choose a job to edit it</option>
          <option v-for="j in jobs" :key="j.id" :id="j.id" :value="j.name">{{ j.name }}</option>
        </select>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-4 text-center">
        <button class="btn-success" @click="addEditJob">{{ addEdit }} Job</button>
      </div>

      <div class="col-4 text-center">
        <button class="btn-danger" v-show="addEdit ==='Edit'" @click="deleteJob">Delete Job</button>
      </div>

      <div class="col-4 text-center">
        <button class="btn-info" @click="clearJob">Clear Job Name</button>
      </div>
    </div>
  </div>
</template>

<script>
import {Job} from "../classes/Job"

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export default {
  name: "ManageJobs",
  data() {
    return {
      job: new Job(),
      addEdit: "Add",
      errMessages: [],

    }
  },
  methods: {
    async deleteJob(){

      if(confirm("Are you sure you wish to delete this job? It could cause holes in the schedule.")){
        let data = new FormData()
        data.set("id",this.job.id)
        await this.$http.post("https://l3kynidfalug2ptxcjnry57vgq0mlcqh.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          let wasDeleted = JSON.parse(response.data);
//          console.log(wasDeleted);
          if(wasDeleted){
            this.$store.commit("deleteJob",this.job)
          }
        }).catch(err =>{
          console.log(err);
          this.errMessages.push("Unable to delete that job. Contact your administrator");
        });
      }
      this.focusJobName();
      this.clearJob();

    },
    switchJob(){

      let job = new Job();
      job.name = this.$refs.jobs.options[this.$refs.jobs.selectedIndex].value;
      job.id = this.$refs.jobs.options[this.$refs.jobs.selectedIndex].id;

      this.job =  job;
      this.addEdit = "Edit";
      this.errMessages = [];

    },
    clearJob(){
      this.job = new Job();
      this.addEdit = "Add";
      this.$refs.jobs.selectedIndex = 0;
      this.errMessages = [];
    },
    getMaxID(){
      return (this.$store.state.jobs.length +2).toString();
    },
    hasSameJob(){
      let hasSameJob = false;
      for (let idx = 0; idx < this.jobs.length; idx++) {
        if(this.$refs.jobName.value.trim() === this.jobs[idx].name){
          hasSameJob = true;
          break;
        }

      }
      //console.log(hasSameJob);
      return hasSameJob;
    },
    async addEditJob(){
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      if(this.$refs.jobName.value.trim() ===""){
        this.errMessages.push("You must give the job type a name")
        return false;
      }
      if (this.hasSameJob()){
        this.errMessages =[];
        this.errMessages.push("A job exists with this name. Please edit/delete the other job first.")
        return false;
      }
      let data = new FormData()
      data.set("id",this.job.id)
      data.set("name",this.job.name)
      //data.set("job",this.job.toJson())
      if(this.job.id !== this.job.DEFAULT_ID){
        // edit the job
        await this.$http.post("https://ln24sy4mwsfhshx4pjksevuapy0racya.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          let job = response.data;
          if(job){
            job = Job.convertObject(job);
            this.$store.commit("editJob",job);
          }
        }).catch(err =>{
          console.log(err);
          this.errMessages.push("There was a problem editing the Job. Contact your administrator.");
        })

      }else{
        // add the job
        await this.$http.post("https://ln24sy4mwsfhshx4pjksevuapy0racya.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          let job = response.data;
          if(job){
           // console.log("job:")
           // console.log(job)
            job = Job.convertObject(job);
            this.$store.commit("addJob",job);
            this.errMessages = null

          }
        }).catch(err =>{
          console.log(err);
          this.errMessages.push("There was a problem adding the Job. Contact your administrator.");
        })
        //this.job.id = this.getMaxID(); // temporary



      }
      this.focusJobName();
      this.clearJob();
    },
    showJob() {
      console.log(this.job.name)
    },
    focusJobName(){
      let focusElem = document.getElementById("jobName");
      focusElem.focus();
    },
  },
  mounted() {
    if(this.$store.getters.FirstThing && this.$store.getters.NoJobs){
      this.errMessages.push("First create some job types that you can schedule");
    }
    this.focusJobName();
  },
  created() {
    // get the jobs


  },
  computed:{
    jobs(){
      return this.$store.state.jobs;
    }
  },
}
</script>

<style scoped>


.errorContainer{
  color:red;

}

</style>
