<template>
  <div class="container-fluid">
    <div class="row">
      <div  class="col-12 errorContainer text-center">
        <ul style="list-style: none">
          <li v-for="errMsg in errMessages" :key="errMsg" >{{errMsg}}</li>
        </ul>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <label for="fName">First Name:&nbsp;</label>
        <input class="form-control" id="fName" ref="fName" v-model="user.fName">
      </div>

      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <label for="mName">Middle Name:&nbsp;</label>
        <input class="form-control" id="mName" ref="mName" v-model="user.mName">
      </div>

      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <label for="lName">Last Name:&nbsp;</label>
        <input class="form-control" id="lName" ref="lName" v-model="user.lName">
      </div>
    </div>
    <br>
    <br>
    <div class="row">
      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
        <label for="password">Password:&nbsp;</label>
        <input class="form-control" type="password" id="password" ref="password">
      </div>
      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
        <label for="confirmPassword">Confirm:&nbsp;</label>
        <input class="form-control" type="password" id="confirmPassword" ref="cPassword">
      </div>
      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
        <label for="isAdmin">Admin?:&nbsp;</label>
        <select class="form-control" id="isAdmin" ref="isAdmin">
          <option value="N" selected>No</option>
          <option value="Y">Yes</option>
        </select>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
        <label for="jobType">Job Type:&nbsp;</label>
        <!--        <input id="job.id"  v-model="job.name">-->
        <select class="form-control" id="jobType" ref="jobType">
          <option value="{}" selected disabled>Choose Job Type</option>
          <option v-for="j in jobs" :key="j" :id="j.id" :value="j.name">{{ j.name }}</option>
        </select>
      </div>

      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
        <label for="pay">Pay per Hour:</label>
        <input class="form-control" id="pay" type="number" v-model="pay" placeholder="0.00">
      </div>
      <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
        <label>&nbsp;</label>
        <button title="Add or Edit Job Type" class="btn btn-sm btn-info form-control " @click="addUserJob">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
               viewBox="0 0 16 16">

            <title>Add or Edit Job Type</title>
            <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
          Job
        </button>
        <!--        <button class="btn btn-sm btn-info " @click="addUserJob" >Add/Edit Job type</button>-->
      </div>
    </div>

    <div class="row" v-show="user.jobs.size >0">
      <div class="col-12 text-center">
        <label for="userJobs">Current Jobs for the Employee</label>
        <ul class="" id="userJobs">
          <li style="list-style-type: none" v-for="(uj,idx) in userJobs" :key="uj" id="uj[0]">

            {{ getJobName(userJobs[idx][0]) }} @ {{ uj[1].toFixed(2) }}/hour&nbsp;&nbsp;&nbsp;
            <!--            <button @click="removeUserJob(userJobs[idx])" class="btn-danger" title="Remove Job From User">-->
            <svg @click="removeUserJob(userJobs[idx])" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" class="bi bi-trash btn-outline-danger"
                 viewBox="0 0 16 16">
              <title>Remove Job From Employee</title>
              <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
            <!--            </button>-->
          </li>
        </ul>
      </div>
    </div>
    <div v-show="users.length >0" class="row">
      <div class="col-12 text-center">
        <br>
        <h6>&nbsp;&nbsp; Or you can edit or delete existing Employees </h6>
        <br>
      </div>
    </div>


    <div class="row" v-show="users.length >0" >
      <div class="col-12 text-center">
        <label for="users">Employees in the System:&nbsp;&nbsp;</label>
        <select class="form-control" id="users" ref="users" @change="switchUser">
          <option selected disabled value="{}">Select Employee to Edit or Delete</option>
          <option v-for="(u,idx) in users" :id="u.id" :key="u.id" :value="idx"
                  :fName="u.fName" :mName="u.mName" :lName="u.lName"
                  :password="u.password" :jobs="u.jobs">{{ u.getFullName() }}
          </option>
        </select>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-4 text-center">
        <button class="btn btn-sm btn-success" @click="addEditUser">{{ buttonMsg }}</button>
      </div>
      <div class="col-4 text-center">
        <button v-show="buttonMsg ==='Edit'" class="btn btn-danger" @click="deleteUser">Delete User</button>
      </div>
      <div class="col-4 text-center">
        <button class="btn btn-sm btn-info " @click="clearUser">Clear</button>
      </div>
    </div>
  </div>
</template>

<script>
import {User} from "../classes/User";
import {Job} from "../classes/Job";
import {useRouter} from "vue-router";

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};
export default {
  name: "ManageUsers",
  data() {
    return {
      user: new User(),
      errMessages: [],
      job: new Job(),
      pay: 0,
      buttonMsg: "Add",
      router : useRouter()
    }
  },
  computed:{
    users(){
      return this.$store.state.users;
    },
    jobs(){
      return this.$store.state.jobs;
    },

    userJobs(){
      let userJobs = this.user.jobs;
      return Array.from(userJobs);
    }
  },
  methods: {
    isExistingUser(){
      let isExisting = false;
      for (let idx = 0; idx < this.users.length; idx++) {
        if (this.user.id !== this.users[idx].id && this.user.getFullName().toLowerCase() === this.users[idx].getFullName().toLowerCase()) {
          isExisting = true;
          break;
        }

      }
      return isExisting;
    },
    checkUser(){
      this.errMessages = [];
      if(!this.user.fName || this.user.fName.trim()===""){
        this.errMessages.push("The Employee must have a first name");
        //return false;
      }
      if(!this.user.lName || this.user.lName.trim() ===""){
        this.errMessages.push("The Employee must have a last name");
        //return false;
      }

      let password = this.$refs.password.value;
      let cPassword = this.$refs.cPassword.value;
      if(!password || password.trim()===""){
        this.errMessages.push("The Employee must have a password");
      }else if(password !== cPassword){
        this.errMessages.push("The Passwords must match");
      }else{
        if (this.buttonMsg !== "Edit") {
          this.user.setPassword(password);
        } else {
          if (this.user.password !== password) {
            this.user.setPassword(password)
          } else {
            this.user.password = password;
          }
        }
      }

      if(this.user.jobs.size ===0){
        this.errMessages.push("You must give the employee at least one job to do")
      }
      if(this.$refs.isAdmin.options[this.$refs.isAdmin.selectedIndex].value ==="Y"){
        this.user.isAdmin = true;
      }
      if(!this.$store.getters.hasAdmin && this.user.isAdmin === false){
        this.errMessages.push("Remember to create an admin user first!");
      }
      return this.errMessages.length <= 0;

    },
    setFocus(){
      let firstName = document.getElementById("fName");
      if(firstName){
        firstName.focus();
      }
    },
    clearUser(){
      this.$refs.password.value ="";
      this.$refs.cPassword.value ="";
      this.$refs.isAdmin.selectedIndex = 0;
      this.user = new User();
      this.buttonMsg = "Add";
      this.errMessages = [];
    },
    async deleteUser(){
      if(this.user.id !== this.user.DEFAULT_ID) {
        let data = new FormData()
        data.set("id",this.user.id)
        await this.$http.post("https://6i3l2etm42ittqplxh7ye53wlm0mcgnw.lambda-url.us-east-1.on.aws/", data,null).then(response =>{
          let wasDeleted;
          wasDeleted = JSON.parse(response.data);
          if(wasDeleted){
            this.$store.commit("deleteUser", this.user);
          }else{
            this.errMessages.push("Unable to delete that user. Contact your administrator");
          }
        }).catch(err =>{
          console.log(err);
          this.errMessages.push("Unable to delete that users. Contact your administrator");
        });

        this.clearUser();
      }else{
        this.errMessages.push("You can not delete a user that is not in the system");
      }
    },
    switchUser(){
      this.errMessages = [];
      this.buttonMsg = "Edit";
      let idx = this.$refs.users.options[this.$refs.users.selectedIndex].value;
      let user = this.users[idx];
      this.$refs.password.value = user.password;
      this.$refs.cPassword.value = user.password;
      this.user = user;
      this.$refs.users.selectedIndex = 0;
    },

    async addEditUser(){
      /// check to see if we have the needed values
      if(!this.checkUser()){
        return false;
      }
      if(this.isExistingUser()){
        if(!confirm("There is a user with that name, are you sure?")){
          return false;
        }
      }
      let data = new FormData()
      // data.set("id",this.user.id)
      // data.set("fName",this.user.fName)
      // data.set("mName",this.user.mName)
      // data.set("lName",this.user.lName)
      // data.set("user",this.user.toJson())
      if(this.user.id !== this.user.DEFAULT_ID){
        // edit the user
        if(this.user.password !== this.$refs.password.value){
          this.user.setPassword(this.$refs.password.value);
        }else{
          this.user.password = this.$refs.password.value;
        }
        data.set("user",this.user.toJson())
        await this.$http.post("https://6vlyem7vvtx4mc5rnq4z2kvaoe0iprhb.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          let user = User.convertObject(response.data);
          if(user){
            this.$store.commit("editUser",user);
          }
        }).catch(err =>{
          console.log(err);
          this.errMessages.push("Unable to edit that Employee. Please contact your administrator.");
        });

      }else{
        // add the user
        data.set("user",this.user.toJson())
        await this.$http.post("https://6vlyem7vvtx4mc5rnq4z2kvaoe0iprhb.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
        //  console.log(response)
          let user = User.convertObject(response.data);
          if(user){
            this.$store.commit("addUser",user);
          }else{
            this.errMessages.push("Unable to add the Employee. Please contact your administrator.");
          }
        }).catch(err =>{
          console.log(err);
          this.errMessages.push("Unable to add the Employee. Please contact your administrator.");
        });
        //this.user.id = this.getMaxID();// temporary


      }
      this.setFocus();
      this.clearUser();
    },
    getMaxID(){
      return (this.$store.state.users.length + 2).toString();
    },

    removeUserJob(job){
      //console.log(Job);
      this.user.deleteJob(job)
    },
    getJobName(id){
      //console.log("ID is: "+id)
      let idx = this.jobs.findIndex(elem =>{return elem.id === id});
      if(idx >=0){
        return this.$store.state.jobs[idx].name;
      }else{
        return "(Deleted Job)";
      }
      //console.log("Index: "+ idx)

    },
    addUserJob() {
      //this.errMessages = [];
      if (this.$refs.jobType.selectedIndex === 0) {
        this.errMessages.push("You must choose a job type prior to adding it");
        return false;
      }
      //||this.pay.trim()==="0" || this.pay.trim() === "0.0" || this.pay.trim() === "0.00"

      console.log(this.pay)
      if ( this.pay.trim === "") {
        this.errMessages.push("You must assign an hourly wage for the user at that job (Enter zero if no pay)");
        return false;
      }
      let chosenJob = new Job();
      chosenJob.name = this.$refs.jobType.options[this.$refs.jobType.selectedIndex].value;
      chosenJob.id = this.$refs.jobType.options[this.$refs.jobType.selectedIndex].id;
      this.user.addJob(chosenJob, parseFloat(this.pay));
      this.$refs.jobType.selectedIndex = 0;
      this.pay = 0;
      this.errMessages = [];
    }
  },
  mounted() {

    let user = new User("admin");
    user.setPassword("admin");
    user.id = "1";
    this.$store.commit("deleteUser",user);

    //if(this.$store.getters.FirstThing ){
      if(this.jobs.length ===0) {
        this.router.push("/admin/manageJobs");
      }else{
        if (this.$store.state.users.length === 0) {
          this.errMessages.push("Next create Employees for the System");
          this.errMessages.push("Remember to create an admin first!");
        }
      }
    //}
    this.setFocus();
  },

}
</script>

<style scoped>

@media (min-width: 576px) {
  body{
    font-size: 75%;
  }
}


@media (min-width: 768px) {
  body {
    font-size: 75%;
  }
}

@media (min-width: 992px) {
  body {
    font-size: 100%;
  }
}


.errorContainer {
  color: red;
}

.responsive-width {
  font-size: 75%;
}

</style>
