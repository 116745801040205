<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        Manage Time Cards
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12">
        <label for="user">Employees:&nbsp;</label>
        <select style="width:100%" class="form-control" name="user" ref="user" id="user" v-model="user" @change="showTimeCard()">
          <option disabled :value="user" >You must choose an Employee</option>
          <option v-for="user in users" :key="user" :value="user" :id="user.id">{{ user.getFullName() }}</option>
        </select>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-3 text-end">
        <label for="dateMonth">
          Date:
        </label>
      </div>
      <div class="col-3 text-start">
        <select id="dateMonth" ref="dateMonth" :value="dateMonth" @change="setMom();showTimeCard()">
          <option v-for="month in months" :key="month" :value="month">{{month}}</option>
        </select>
      </div>
      <div class="col-3 text-center">
        <select ref="dateDay" :value="dateDay" @change="setMom();showTimeCard()">
          <option v-for="day in mom.daysInMonth()" :key="day" :value="normalize(day)">{{ normalize(day) }}</option>
        </select>
      </div>
      <div class="col-3 text-center">
        <select ref="dateYear" :value="dateYear" @change="setMom();showTimeCard()">
          <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>
    </div>
    <br><br><br>
    <div v-for="tci in timeCardInfo" :key="tci">
      <div class="row">
        <div class="col-3 text-end">
          Job:
        </div>
        <div class="col-9 text-start">
          {{ tci.job }}
        </div>
      </div>
    <div class="row" >
      <div class="col-3 text-end">
        Start time:
      </div>
      <div class="col-9 text-start">
          {{ tci.validateCheckin.time.local()}}
      </div>
    </div>

    <div class="row">
      <div class="col-3 text-end">
        End time:
      </div>
      <div class="col-9 text-start">
            {{ tci.clockOut.time.local()}}
      </div>
    </div>
    <div class="row">
      <div class="col-3 text-end">
        Total time:
      </div>
      <div class="col-9 text-start">
          {{ new TimeCard (tci.validateCheckin, tci.clockOut).getHoursWorked() }}
      </div>
    </div>
    </div>
    <br><br><br>


  </div>

</template>
<script>
import {User} from "../classes/User"
import moment from "moment/moment";
import {useRouter} from "vue-router";
import {Appointment} from "@/classes/Appointment";
import {TimeCard} from "../classes/TimeCard";

export default {
  name: "ManageTimeCards",
  data(){
    return{
      user:new User(),
      months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      years: this.getYears(),
      mom: {},
      today: {},
      router: useRouter(),
      timeCardInfo: [],
    }
  },
  methods:{
    async showTimeCard(){
      if(this.user.id !== this.user.DEFAULT_ID) {
        console.log("User:")
        console.log(this.user)
        console.log("Date Time:")
        console.log(this.mom)
        let data = new FormData()
        data.set("user",this.user.toJson())
        data.set("includedMonth",this.mom.toJSON().toString())
        await this.$http.post("https://wi2vgoqnk5at7wzsrozrufzlma0atqmy.lambda-url.us-east-1.on.aws/",data, null)
            .then(response =>{
              let timeCardInfoArray = response.data
              console.log(timeCardInfoArray)
              if (timeCardInfoArray){
                for (let idx = 0;idx < timeCardInfoArray.length;idx ++){
                  let tcI = Appointment.convertObject(timeCardInfoArray[idx])
                  this.timeCardInfo.push(tcI)
                }
              }

            })
            .catch(err =>{
              console.log(err)
            })


      }
    },
    normalize(num) {

      return num.toString().length === 2 ? num : "0" + num;
    },

    getYears(){
      let yrs = []
      //let thisYear = this.appointment.duration.start.format("YYYY") +3;
      for(let i = -3;i < 3;i++){
        yrs.push(new moment().add(i,"year").format("YYYY"));
      }
      return yrs;
    },
    setMom(){
      let day = {};
      day.month = this.$refs.dateMonth.selectedIndex;  /// month is index based
      day.date = parseInt(this.$refs.dateDay.options[this.$refs.dateDay.selectedIndex].value);
      day.year = parseInt(this.$refs.dateYear.options[this.$refs.dateYear.selectedIndex].value);
      day.hour = this.mom.hour();
      day.minute = this.mom.minute();
      day.second = this.mom.second();
      day.millisecond = this.mom.millisecond();
      let newDay = new moment().set(day);
      this.mom = newDay;
    },
  },
  computed:{
    TimeCard() {
      return TimeCard
    },
    users(){
      // console.log("state:")
      // console.log(this.$store.state)
      return this.$store.state.users
    },
    dateMonth(){

      return this.mom.format("MMM");
    },
    dateDay(){

      return this.mom.format("DD");
    },
    dateYear(){

      return this.mom.format("YYYY");
    },
  },
  created(){
    this.today = new moment().startOf("day");
    this.mom = new moment().startOf("day");
  }

}
</script>