<template>
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg nav-pills navbar-light" style="background-color: #B6E5D8;">
      <a class="navbar-brand" href="#"><img alt="Scheduler" src="../../public/SmallCalendar.png"/> </a><span class="logo text-left"> Scheduler</span>
      <button  class="navbar-toggler" type="button" data-bs-toggle="collapse"  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto mx-5">
          <li class="nav-item">

            <router-link
                v-show="isLoggedIn"
                id="Home"
                @mousedown.native="makeAdminInactive"
                class="nav-link"
                :to="{ name: 'Home' }"
                active-class="active"
                exact
            >Home</router-link
            >
          </li>
          <li v-show="isAdmin"  class="nav-item dropdown">

            <div v-show="isAdmin" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                 data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Admin
            </div>

            <div v-show="isAdmin" class="dropdown-menu" aria-labelledby="Admin dropdown">

              <router-link @mousedown.native="makeAdminActive" v-show="isAdmin" class="nav-link dropdown-item"
                           :to="{name:'ManageUsers'}" active-class="active" exact>
                Manage Employees
              </router-link>
              <router-link @mousedown.native="makeAdminActive" v-show="isAdmin" class="nav-link dropdown-item"
                           :to="{name:'ManageJobs'}" active-class="active" exact>
                Manage Jobs
              </router-link>
              <router-link @mousedown.native="makeAdminActive" v-show="isAdmin" class="nav-link dropdown-item"
                           :to="{name:'ManageTimeCards'}" active-class="active" exact>
                Manage Time Cards
              </router-link>
            </div>
          </li>
          <li v-show="isLoggedIn" class="nav-item">
            <a v-show="isLoggedIn" href="#" @click="logOut" class="nav-link">Log Out</a>
          </li>
        </ul>

      </div>
    </nav>
  </div>
</template>

<script>

//import store from "@/store/store"

export default {
  name: "NavBar",
  data() {
    return {

    }
  },
  mounted() {
    /*if(this.$store.getters.FirstThing){
      console.log("making admin active");
      this.makeAdminActive();
    }else{
      console.log("Making admin inactive...")
      this.makeAdminInactive();
    }*/
  },
  computed:{
    isLoggedIn(){
      return this.$store.state.loggedInUser !=null;
    },
    isAdmin() {
      this.$forceUpdate()
     // console.log("store: ")
    //  console.log(this.$store.state.loggedInUser)
     // console.log(this.$store.state.loggedInUser !=null && this.$store.state.loggedInUser.isAdmin)
      return this.$store.state.loggedInUser !=null && this.$store.state.loggedInUser.isAdmin;
      //return true;
    }
  },
  methods: {

    logOut(){
      this.makeAdminInactive();

      this.$router.push("/login");
      //this.$store.state.loggedInUser = null;
      this.$store.commit("setLoggedInUser");
    },

    makeAdminActive(){
      let adminLink = document.getElementById("navbarDropdown");
      adminLink.classList.add("active");

      let homeLink = document.getElementById("Home");
      homeLink.classList.remove("active");

    },
    makeAdminInactive(){
      let adminLink = document.getElementById("navbarDropdown");
      adminLink.classList.remove("active");
      let homeLink = document.getElementById("Home");
      homeLink.classList.add("active");
      return true;
    }

  },
}
</script>

<style scoped>
.logo{
  font-family: "Bookman Old Style","serif" !important;
  font-size: 2em;
  color:red;
  overflow: hidden;
  text-overflow: ellipsis;

}
a :active{
  color:blue;
}


</style>
