<template>
  <div>
  <NavBar></NavBar>

    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component"  />
      </transition>
    </router-view>


  </div>

</template>

<script>


import NavBar from "./components/NavBar";
//import {OSMService} from"@/services/OSMService";



export default {

  name: 'App',
  components: {NavBar},
  async created() {
    // let osm = new OSMService();
    // console.log("getting lat and lng..")
    // let location = await osm.getLatAndLong();
    // if(location && location.coords) {
    //   console.log(location.coords.latitude)
    //   console.log(location.coords.longitude)
    // }
  },
  data() {
    return {
      // lat: 0,
      // long:0,
    }
  },


}
</script>

<style>
body{
  background-color:#FFE5B4;
}

.fade-enter,
.fade-leave-to {
  transition: opacity 1s ease;
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
  /*opacity: 1;*/
}

</style>
