//const CryptoJS = require("crypto-js");
import sha512 from 'crypto-js/sha512';


export class User{
  DEFAULT_ID = "0";
  id = this.DEFAULT_ID;
  fName = "";
  mName = "";
  lName = "";
  password = "";
  isAdmin = false;
  jobs = new Map();
  jobsArray = [];
  payArray = [];
  constructor(fName,mName,lName) {
    this.fName = fName||"";
    this.mName = mName || "";
    this.lName = lName || "";
  }

  static replacer(key, value) {
    if(value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  static reviver(key, value) {
    if(typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  toJson(){

    return JSON.stringify(this);
  }
  setJobs(){
    this.jobs = new Map();
    for(let idx = 0;idx < this.jobsArray.length;idx++){
      this.jobs.set(this.jobsArray[idx],this.payArray[idx]);
    }
  }
  addJob(job,pay){
    if(job instanceof Object  && job.id !== job.DEFAULT_ID){
      this.jobs.set(job.id.toString(),pay);
      let idx = this.jobsArray.findIndex(elem =>{return elem === job.id})
      if(idx === -1) {
        this.jobsArray.push(job.id.toString());
        this.payArray.push(pay);
      }else{
        this.jobsArray.splice(idx,1,job.id.toString())
        this.payArray.splice(idx,1,pay)
      }
    }
  }

  deleteJob(j){

   if(j instanceof Array ){
     this.jobs.delete(j[0]);
     // remove from the arrays
     let idx = this.jobsArray.findIndex(elem =>{return elem === j[0]})
     this.jobsArray.splice(idx,1)
     this.jobsArray.splice(idx,1)
   }else{
     this.jobs.delete(j.id);
     let idx = this.jobsArray.findIndex(elem =>{return elem === j.id})
     this.jobsArray.splice(idx,1)
     this.jobsArray.splice(idx,1)

   }

  }

  editJob(job, pay) {
    if (this.jobs.has(job.id.toString())) {
      //this.deleteJob(job);
      this.addJob(job, pay);
    }
  }

  calculatePayForRota(rota, jobs) {
    let jobID = -1;
    for (let idx = 0; idx < jobs.length; idx++) {
      if (rota.job === jobs[idx].name) {
        jobID = jobs[idx].id;
        break;
      }

    }
    if (jobID === -1) {
      return "$0.00";// can't calculate the job due to the job being deleted
    }
    let hourlyRate = this.jobs.get(jobID);
    let pay = (rota.halfHours / 2) * hourlyRate;
    pay = pay.toFixed(2);
    return "$" + pay;

  }

  getPayScale() {
    let payScale = [];

    for (let elem of this.jobs.entries()) {
      payScale.push(elem[0] + " " + elem[1]);
    }
    return payScale;
  }

  static convertObject(obj){
    let user = new User();
    Object.assign(user,obj);
    user.setJobs();
    return user;
  }

  shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  isEqual(user){
    if(user instanceof User){
      return this.shallowEqual(this,user);

    }else{
      return false;
    }
  }

  getFullName(){
    let space = "";
    if(this.mName.trim()!==""){
      space = " ";
    }
    return this.fName + " " + this.mName + space + this.lName;
  }
  setPassword(password){
    this.password = sha512(password).toString();

  }
  getPassword(){
    return this.password;
  }
  comparePasswords(pPW){
    return this.getPassword() === sha512(pPW).toString();
  }

  toJson(){

    return JSON.stringify(this);
  }

}
