<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 text-right">
        <label for="view">View:&nbsp;</label>
        <select id="view" name="view" v-model="view">
          <option value="Day" >Day</option>
          <option value="Week">Week</option>
          <option value="Schedule">Schedule</option>
        </select>
      </div>
      <div class="col-6 text-left" v-if="view==='Day'">
        <label for="dateMonth">
          Date: &nbsp;
        </label>
        <select id="dateMonth" ref="dateMonth" :value="dateMonth" @change="setMom">
          <option v-for="month in months" :key="month" :value="month">{{month}}</option>
        </select>
        &nbsp;
        <select ref="dateDay" :value="dateDay" @change="setMom">
          <option v-for="day in mom.daysInMonth()" :key="day" :value="normalize(day)">{{ normalize(day) }}</option>
        </select>
        <select ref="dateYear" :value="dateYear" @change="setMom">
          <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>

      <div v-if="view==='Day'" class="col-12 text-center align-self-center"><span title="Back one day"
                                                                                  @click="goBackOneDay"
                                                                                  style="font-weight: bolder;color:blue;cursor:pointer;">&lt;&lt;&nbsp;&nbsp;</span>{{ mom.format("MMM Do") }}<span
          title="Forward one day" @click="goForwardOneDay" style="font-weight: bolder;color:blue;cursor:pointer;">&nbsp;&nbsp;&gt;&gt;</span>
      </div>

      <div v-if="view ==='Week' || view ==='Schedule'" class="row">
        <div class="col-12 text-center"><span title="Back one week" @click="goBackOneWeek"
                                              style="font-weight: bolder;color:blue;cursor:pointer;">&lt;&lt;&nbsp;&nbsp;</span>Week
          of {{ getBeginningOfWeek().format("MMM Do") }}<span title="Forward one week" @click="goForwardOneWeek"
                                             style="font-weight: bolder;color:blue;cursor:pointer;">&nbsp;&nbsp;&gt;&gt;</span>
        </div>
      </div>

    </div>

    <div class="row  ">
      <div class="col-12">
        <p class="text-center" v-if="view ==='Schedule'">To Add or Edit a Schedule, CLICK on the Day or the Existing Schedule in the Day(s) Below.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="margin-top: 10px" v-if="view ==='Day'">
        <Day :today="today" @deleterota="deleteRota" @rotachange="rotaChange"  :moment="mom"  :rotas="appointments.filter((el)=> {return el != null && el.isOnSameDayAs(mom.clone())})" ></Day>
      </div>
      <div v-for="num in numbers" :key="num" class=" col-3" style="margin-top: 10px" v-else-if="view==='Week'">

        <Day :today="today" @deleterota="deleteRota" @rotachange="rotaChange" :refNum="num"  :moment="mom.clone().add(num,'day')"  :rotas="appointments.filter((elem)=> {return elem !=null && elem.isOnSameDayAs(mom.clone().add(num,'day'))})" ></Day>
      </div>
      <div class="col-12" v-if="view === 'Schedule'">
        <schedule @deleterota="deleteRota" @rotachange="rotaChange" :moment="mom"></schedule>
      </div>
    </div>
  </div>
</template>

<script>
import Day from "../components/Day";
import {Appointment} from "../classes/Appointment";

import moment from "moment"
import Schedule from "../views/Schedule";
import {useRouter} from "vue-router";
//import { decycle, retrocycle } from "json-decycle";


const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};
function jsonStringifyRecursive(obj) {
  const cache = new Set();
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        console.log("circular reference")
        console.log(key)
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.add(value);
    }
    return value;
  }, 4);
}

export default {
name: "Home",
  components: {Schedule,  Day},
  data() {

    return {
      jobs: [],
      //appointments: this.$store.state.appointments,
      users: [],
      mom: {},
      today: {},
      numbers: [],
      view: "Schedule",
      months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      years: this.getYears(),
      router: useRouter(),
    }
  },
  mounted() {


    if(this.$store.getters.FirstThing){
      if(this.$store.getters.NoJobs){
        this.router.push("/admin/manageJobs");
      }else {
        this.router.push("/admin/manageUsers");
      }
    }
  },

  computed:{
   appointments(){
     return this.$store.state.appointments;
   },
    dateMonth(){

      return this.mom.format("MMM");
    },
    dateDay(){

      return this.mom.format("DD");
    },
    dateYear(){

      return this.mom.format("YYYY");
    },
  },
  methods: {

    getBeginningOfWeek(){
      return this.mom.clone().weekday(-7).add(7,"days")
    },
    getAbsoluteMonths(momentDate) {
      let months = Number(momentDate.format("MM"));
      let years = Number(momentDate.format("YYYY"));
      return months + (years * 12);
    },
    async goBackOneDay() {
      let oneDayBack = this.mom.clone().subtract(1, "days")
      let data = new FormData()
      data.set("moment",oneDayBack.toJSON())
      if (this.getAbsoluteMonths(this.mom) !== this.getAbsoluteMonths(oneDayBack)) {
        //console.log("getting new months (back)...");
        await this.$http.post("https://v6qpbhpolxm5ugtondpjvn7lxu0qhwiw.lambda-url.us-east-1.on.aws/",data,null ).then(response => {
          let rota = response.data;
          if (rota) {
            for (let idx = 0; idx < rota.length; idx++) {
              rota[idx] = Appointment.convertObject(rota[idx]);

            }
            this.$store.commit("setRota", rota);
          }
        }).catch(err => {
          console.log(err);
        });
      }
      this.mom = oneDayBack;
      this.setNumbers();
    },
    async goBackOneWeek() {
      let oneWeekBack = this.mom.clone().subtract(7, "days")
      let data = new FormData()
      data.set("moment",oneWeekBack.toJSON())
      if (this.getAbsoluteMonths(this.mom) !== this.getAbsoluteMonths(oneWeekBack)) {
        //console.log("getting new months (back)...");
        await this.$http.post("https://v6qpbhpolxm5ugtondpjvn7lxu0qhwiw.lambda-url.us-east-1.on.aws/",data,null).then(response => {
          let rota = response.data;
          if (rota) {
            for (let idx = 0; idx < rota.length; idx++) {
              rota[idx] = Appointment.convertObject(rota[idx]);

            }
            this.$store.commit("setRota",rota);
          }
        }).catch(err =>{
          console.log(err);
        });
      }
      this.mom = oneWeekBack;
      this.setNumbers();
    },
    async goForwardOneWeek(){
      let oneWeekForward = this.mom.clone().add(7,"days")
      let data = new FormData()
      data.set("moment",oneWeekForward.toJSON())
      if(this.getAbsoluteMonths(this.mom)!== this.getAbsoluteMonths(oneWeekForward)){
        // console.log("getting new months (forward)")
        await this.$http.post("https://v6qpbhpolxm5ugtondpjvn7lxu0qhwiw.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          let rota = response.data;
          if(rota){
            for (let idx = 0; idx < rota.length; idx++) {
              rota[idx]  = Appointment.convertObject(rota[idx]);

            }
            //console.log("setting new rota")
            this.$store.commit("setRota", rota);
          }
        }).catch(err => {
          console.log(err);
        });
      }
      this.mom = oneWeekForward;
      this.setNumbers();
    },

    async goForwardOneDay() {
      let oneDayForward = this.mom.clone().add(1, "days")
      let data = new FormData()
      data.set("moment",oneDayForward.toJSON())
      if (this.getAbsoluteMonths(this.mom) !== this.getAbsoluteMonths(oneDayForward)) {
        // console.log("getting new months (forward)")
        await this.$http.set("https://v6qpbhpolxm5ugtondpjvn7lxu0qhwiw.lambda-url.us-east-1.on.aws/",data, null).then(response => {
          let rota = response.data;
          if (rota) {
            for (let idx = 0; idx < rota.length; idx++) {
              rota[idx] = Appointment.convertObject(rota[idx]);

            }
            //console.log("setting new rota")
            this.$store.commit("setRota", rota);
          }
        }).catch(err => {
          console.log(err);
        });
      }
      this.mom = oneDayForward;
      this.setNumbers();
    },
    normalize(num) {

      return num.toString().length === 2 ? num : "0" + num;
    },
    async setMom() {
      let day = {};
      day.month = this.$refs.dateMonth.selectedIndex;  /// month is index based
      day.date = parseInt(this.$refs.dateDay.options[this.$refs.dateDay.selectedIndex].value);
      day.year = parseInt(this.$refs.dateYear.options[this.$refs.dateYear.selectedIndex].value);
      day.hour = this.mom.hour();
      day.minute = this.mom.minute();
      day.second = this.mom.second();
      day.millisecond = this.mom.millisecond();
      let newDay = new moment().set(day);
      let data = new FormData()
      data.set("moment",newDay.toJSON())

      if(this.getAbsoluteMonths(this.mom)!== this.getAbsoluteMonths(newDay)){
        await this.$http.post("https://v6qpbhpolxm5ugtondpjvn7lxu0qhwiw.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          let rota = response.data;
          if(rota){
            for (let idx = 0; idx < rota.length; idx++) {
              rota[idx] = Appointment.convertObject(rota[idx]);

            }
            this.$store.commit("setRota",rota);
          }
        })
      }

      this.mom = newDay;

      this.setNumbers();
    },
    getYears(){
      let yrs = []
      //let thisYear = this.appointment.duration.start.format("YYYY") +3;
      for(let i = -3;i < 3;i++){
        yrs.push(new moment().add(i,"year").format("YYYY"));
      }
      return yrs;
    },
    setNumbers(){
      ////////console.log(this.mom.format("dd"));
      let startNum = 0;
      this.numbers = [];
      switch (this.mom.format("dd")){
        case "Su":
          startNum = 0;
          break;
        case "Mo":
          startNum = -1;
          break;
        case "Tu":
          startNum = -2;
          break;
        case "We":
          startNum = -3;
          break;
        case "Th":
          startNum = -4;
          break;
        case "Fr":
          startNum = -5;
          break;
        case "Sa":
          startNum = -6;
          break;


      }// end switch
      this.numbers = [];
      let ctr = 0;
      while(ctr<=6){
        this.numbers.push(startNum + ctr);
        ctr++;
      }

    },
    async rotaChange(arg){
     // console.log("rotaChanged....")
      //console.log(arg)
      let rota = new Appointment()
      rota = Object.assign(rota,arg);
     console.log("rota:")
      console.log(rota)
      let data = new FormData()
      data.set("id",rota.id)
      data.set("startHalfHours",rota.startHalfHours)
      data.set("halfHours",rota.halfHours)
      data.set("job",rota.job)

      //console.log("User rotaChange:")
      //console.log(rota.User.toJson())
      data.set("user",rota.User.toJson())
      data.set("month",rota.month)
     // console.log("checkin json")
      //console.log(jsonStringifyRecursive(rota.checkin))
      data.set("checkIn",jsonStringifyRecursive(rota.checkin))
      data.set("validateCheckin",jsonStringifyRecursive(rota.validateCheckin))
      // console.log("Clock out:")
      // console.log(jsonStringifyRecursive(rota.clockOut))
      data.set("clockOut",jsonStringifyRecursive(rota.clockOut))
      data.set("isValidated",rota.isValidated)
      data.set("hasClockedOut",rota.hasClockedOut)
      data.set("duration",JSON.stringify(rota.duration))
      if(rota.id !==arg.DEFAULT_ID){
      // edit rota
        await this.$http.post("https://ufd7dmgdajxubbsfwxuoyld7n40shsog.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          let editedRota = Appointment.convertObject(response.data);
          console.log("edited appt")
          console.log(editedRota)
          if(editedRota.hasclockedout === true){
            editedRota.hasClockedOut = true;
          }
          if(editedRota){
            this.$store.commit("editAppointment",editedRota);
          }
        })

      }else{
        // add new appointment
        //console.log("should be adding a new rota on home");
        await this.$http.post("https://ufd7dmgdajxubbsfwxuoyld7n40shsog.lambda-url.us-east-1.on.aws/", data,null).then(response => {
          let newRota = Appointment.convertObject(response.data);
          if (newRota) {
            // console.log("new rota")
            // console.log(newRota)
            this.$store.commit("addAppointment", newRota)
          }
        }).catch(err => {
          console.log(err);
        });
        // await this.getMaxID().then((id)=>{
        //   rota.id = id;
        //   //this.appointments.push(rota);
        //   this.$store.commit("addAppointment",rota)
        // });



      }

    },
    async deleteRota(arg){
      //console.log("Home received delete...")
      let rota = new Appointment();
      rota = Object.assign(rota,arg);
      let data = new FormData()
      data.set("id",rota.id)
      await this.$http.post("https://inqt2zr36oc4uqcxmclbsx37lu0krrog.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
        let wasDeleted = JSON.parse(response.data);
       // console.log("was deleted")
        //console.log(wasDeleted)
        if(wasDeleted){
          this.$store.commit("deleteAppointment",rota);
        }
      }).catch(err =>{
        console.log(err)
      })


    },
    // async getMaxID(){
    //   // to simulate an async service call by ajax of 2 seconds
    //   return new Promise(resolve=>{
    //     setTimeout(()=>{
    //       resolve((this.$store.getters.AppointmentLength + 2).toString());
    //     },1000)// meant to show a 1 second ajax delay
    //   })
    //   //return this.appointments.length + 2;
    // },
    // getDailyAppointments(mom){
    //   let daily = [];
    //   //////console.log("get daily appointments")
    //   //////console.log(this.appointments.length);
    //   daily = this.appointments.filter((elem)=> {return Appointment.convertObject(elem).isOnSameDayAs(mom)})
    //   return daily;
    // },
    fixAppointmentObjects(brokenAppts){
      //////console.log("fixing appointments")
      //const appts = [];
      for(let idx = 0;idx < brokenAppts.length;idx++){
        //////console.log("looop")
        //appts.push(Appointment.convertObject(brokenAppts[idx]));
        this.$store.commit("addAppointment",Appointment.convertObject(brokenAppts[idx]));
      }
      //this.appointments = appts;
    },
    // getAppointments() {
    //   let appts = [];
    //
    //   let appt = new Appointment(new User("test","","user"),"testJob");
    //   appt.setDurationEnd(appt.duration.start.clone().add(2,"hours"));
    //   appt.id = 1;
    //   appts.push(appt);
    //
    //   let appt2 = new Appointment(new User("mike","","user"),"secondJob");
    //   appt2.id = 2;
    //
    //
    //   appt2.setDurationStart(appt.duration.start.clone().add("30","minutes"));
    //   appt2.setDurationEnd(appt2.duration.start.clone().add(7,"hours"));
    //
    //   appts.push(appt2);
    //
    //   let appt3 = new Appointment(new User("different","","Guy"),"different Job");
    //   appt3.id = 3;
    //   appt3.setDurationEnd(new moment().endOf("day"));
    //   appt3.setDurationStart(appt3.duration.end.clone().subtract(60,'minutes'));
    //
    //   appts.push(appt3);
    //
    //   let appt4  = new Appointment(new User("different","","Guy"),"next day");
    //   appt4.setDurationStart(appt2.duration.start.clone().add(1,"day"));
    //   appt4.setDurationEnd(appt2.duration.end.clone().add(1,"day"));
    //   appt4.id = 4;
    //
    //   appts.push(appt4);
    //
    //   let appt5  = new Appointment(new User("really different","","Guy"),"two days");
    //   appt5.setDurationStart(appt2.duration.start.clone().add(6,"day"));
    //   appt5.setDurationEnd(appt2.duration.end.clone().add(6,"day"));
    //   appt5.id = 5;
    //
    //   appts.push(appt5);
    //
    //   return appts;
    //
    // },
    // async getAppointmentsStatic(){
    //   let appts = [];
    //   const srs = StaticRotaService.newStaticRotaService("/rota.json");
    //   await srs.getRota().then(
    //       data => {
    //         ////console.log(data);
    //         appts = (data);
    //       }
    //   ).catch(err =>
    //   {
    //     console.log(err);
    //     });
    //   await this.fixAppointmentObjects(appts);
    //   // await srs.getUsers().then(
    //   //     data =>{
    //   //       this.users = data;
    //   //       if(this.users.length ===0){
    //   //         let user = new User("admin");
    //   //         user.setPassword("admin");
    //   //       }
    //   //     }
    //   // )
    // },
  },
  created() {
    this.today = new moment().startOf("day");
    this.mom = new moment().startOf("day");

    this.setNumbers();
  },
}
</script>

<style scoped>

</style>
