<template>
  <transition name="fade">
    <div class="myModal container-fluid" v-if="showModal">

      <div class="row">
        <div class="col-12 title " style="margin-bottom: 10px">
          {{ titleMsg }}
        </div>
      </div>
      <MapModal @closemap="showMap = false;" v-if="showMap" :display-name="this.checkin.display_name"
                :lat="parseFloat(this.checkin.lat)" :lon="parseFloat(this.checkin.lon)"
                :ver-lat="dAppt.validateCheckin?parseFloat(dAppt.validateCheckin.lat):0"
                :ver-lon="dAppt.validateCheckin?parseFloat(dAppt.validateCheckin.lon):0"
                :ver-display-name="dAppt.validateCheckin?dAppt.validateCheckin.display_name:''"></MapModal>
      <div class="row">
        <div class="col-12 errorContainer text-center">
          <ul style="list-style: none">
            <li v-for="errMsg in errMessages" :key="errMsg">{{ errMsg }}</li>
          </ul>
        </div>
      </div>
      <br><br><br>
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-6 text-center">
              <label for="users">Employees:&nbsp;</label>
              <select :disabled="true" class="form-control" id="users" ref="users" @change="setUser()"
                      v-model="dAppt.User.id" >
                <option disabled selected value="{}">Choose an Employee</option>
                <option v-for="(u,idx) in users" :key="u.id" :id="idx" :value="u.id"  >{{ u.getFullName() }}</option>
              </select>
            </div>
            <div class="col-6 text-center">
              <label for="job">Job:&nbsp;</label>
              <select :disabled="disabled" class="form-control" id="job" ref="job" v-model="dAppt.job"
                      :change="setDurations">
                <option disabled selected value="{}">{{ jobMessage }}</option>

                <option v-for="(j,idx) in userJobs" :key="j" :id="userJobs[idx][0]"
                        :value="getJobName(userJobs[idx][0])">{{ getJobName(userJobs[idx][0]) }}
                </option>
              </select>
            </div>
          </div>


          <br>
          <div class="row" v-show="dAppt.job">
            <div class="col-4 col-md-4 col-sm-4 col-xs-4 text-center">
              <form class="form-inline">
                <label for="dateMonth">
                  Date: &nbsp;
                </label>
                <select :disabled="disabled" @change="setDurations" class="form-control" id="dateMonth" ref="dateMonth"
                        :value="dateMonth">
                  <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
                </select>

                <select :disabled="disabled" @change="setDurations" class="form-control" id="dateDay" ref="dateDay"
                        :value="dateDay">
                  <option v-for="day in appointment.duration.start.daysInMonth()" :key="day" :value="normalize(day)">
                    {{ normalize(day) }}
                  </option>
                </select>

                <select :disabled="disabled" @change="setDurations" class="form-control" id="dateYear" ref="dateYear"
                        :value="dateYear">
                  <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
              </form>
            </div>
            <div class="col-4 col-md-4 col-sm-4 col-xs-4 text-center">
              <form class="form-inline" @submit.prevent="">
                <label for="startHour">
                  Start: &nbsp;
                </label>


                <select :disabled="disabled" @change="setDurations" class="form-control" id="startHour" ref="startHour"
                        :value="startHours">
                  <option v-for="num in 12" :key="num" :value="normalize(num)">{{ normalize(num) }}</option>
                </select>
                :
                <select :disabled="disabled" @change="setDurations" class="form-control" ref="startMin"
                        :value="startMin">
                  <option selected value="00">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>

                </select>
                &nbsp;
                <select :disabled="disabled" @change="setDurations" class="form-control" ref="startAMPM"
                        :value="startAMPM">
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </form>
            </div>


            <div class="col-4  col-md-4 col-sm-4 col-xs-4 text-center">
              <form class="form-inline" @submit.prevent="">
                <label for="endHours">
                  End: &nbsp;
                </label>
                <select :disabled="disabled" @change="setDurations" class="form-control" id="endHours" ref="endHours"
                        :value="endHours">
                  <option v-for="num in 12" :key="num" :value="normalize(num)">{{ normalize(num) }}</option>
                </select>
                :
                <select :disabled="disabled" @change="setDurations" class="form-control" ref="endMin" :value="endMin">
                  <option selected value="00">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <!--                  <option v-for="num in 59" :key="num" :value="normalize(num)">{{ normalize(num) }}</option>-->
                </select>
                &nbsp;
                <select :disabled="disabled" @change="setDurations" class="form-control" ref="endAMPM" :value="endAMPM">
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </form>
            </div>

          </div>
          <div class="row">

            <div v-show="!disabled" class=" col-6  col-md-6 col-sm-6 col-xs-6 text-center">
              <!--          <button @click="showMap = !showMap">Show Map</button>-->
              <label for="place">Address:</label>
              <input ref="address" size="20" @change="$event.stopImmediatePropagation()" class="form-control" id="place"
                     v-model="place"/>
              <br>
              <span>Or choose an address</span>
              <br>
              <select ref="addresses"  @change="mapAddress()">
                <option disabled selected value="{}">Addresses</option>
                <option  v-for="checkIn in checkIns" :key="checkIn.place_id" :value="checkIn.display_name" >{{ checkIn.display_name }}</option>
              </select>
              <br><br>
              <button ref="mapper" @click.prevent="mapPlace" class="btn btn-warning">Map Address</button>

            </div>
            <div class=" col-6  col-md-6 col-sm-6 col-xs-6 text-center " :class="centerMeObj">
              <br><br>
              <span>
                <a title="Click To Display Map" href="#" v-show="this.checkin.lat !=='0' && this.checkin.display_name"
                   @click="showMap = true">{{ checkin.display_name }}</a>
              </span>
              <br><br><br>
              <div v-if="dAppt.checkin.display_name !=='' && buttonMsg !== 'Add'" class="col-12 text-center">
                <button class="btn btn-success" @mousedown="validateRota">
                  {{ clockInMessage() }}
                </button>
                <br><br><br>
                <button v-if="loggedInUser.isAdmin" class="btn btn-outline-success"
                        @mousedown="this.handleOverride();addEditRota($event);">Override {{ clockInMessage() }}
                </button>
              </div>
            </div>
            <div v-show="!disabled" class="col-4 col-md-4 col-sm-4 col-xs-4 text-center">

            </div>

          </div>

        </div>
      </div>
      <br><br>

      <br><br>
      <div class="row">
        <div v-if="!disabled" class="col-4 col-md-4 col-sm-4 col-xs-4 text-center">
          <button class="btn btn-success" @mousedown="addEditRota($event);closeModal($event)">
            {{ buttonMsg }}
          </button>
        </div>


        <div v-if="!disabled" class="col-4 col-md-4 col-sm-4 col-xs-4 text-center">
          <button v-show="buttonMsg==='Edit'" class="btn btn-danger" @mousedown="deleteRota($event)">
            Delete
          </button>
        </div>

        <div class="col-4 col-md-4 col-sm-4 col-xs-4 text-center" :class="centerMeObj">
          <button class="btn btn-info" @mousedown="closeModal($event)">
            Close
          </button>
        </div>
      </div>
      <div v-if="dAppt.isValidated" class="row">
        <div class="col-12 text-center" style="color:green">
          Congratulations you have successfully {{ clockOutMessage() }} at {{getValidatedCheckingTime() }}

        </div>

      </div>
      <!--      <button @click="disableAll">Disable</button>-->
    </div>

  </transition>
</template>

<script>
import {Appointment} from "../classes/Appointment";
import moment from 'moment';
import MapModal from "./MapModal";
import {CheckIn} from "../classes/CheckIn";
import {OSMService} from "../services/OSMService";
import {
  distanceTo
} from 'geolocation-utils'


export default {
  name: "RotaModal",
  components: {MapModal},
  // updated() {
  //   console.log("setting user:")
  //   this.dAppt.User = this.users[this.$refs.users.selectedIndex-1];
  // },
  beforeUpdate() {
    for(let idx =0;idx < this.users.length;idx++){
      if(this.users[idx].id.trim() === this.dAppt.User.id.trim()){
        // console.log("found one")
        // console.log(this.dAppt.User.getFullName())
        this.employeeIdx = idx +1;

        // if(this.$refs.users !== null) {
        //   this.$refs.users.selectedIndex = idx + 1
        //   console.log("idx")
        //   console.log(this.$refs.users.selectedIndex)
        //   //this.$refs.users.options.selectedIndex = idx +1
        //   //this.$refs.users.value = this.dAppt.User
        // }


        break;
      }
    }
  },
  updated() {
      // console.log("in updated")
      // console.log(this.dAppt)

    if(this.$refs.users !== null && typeof this.$refs.users != "undefined") {
      // console.log("user refs")
      // console.log(this.$refs.users)
      this.$refs.users.selectedIndex = this.employeeIdx
      // console.log(this.employeeIdx)
      this.dAppt.User = this.users[this.employeeIdx -1]

      // try to force an update
      //this.$refs.users.options[this.employeeIdx].key = this.dAppt.User.getFullName()
    }
  },
  async mounted() {
    this.place = "";
    this.checkin = new CheckIn();
    await this.$http.get("https://csonxaw6isrgnexhayzyjuabom0tvvfg.lambda-url.us-east-1.on.aws/").then(response =>{
      let checkins = response.data
      //console.log("checkins")
      // console.log(checkins)
      if(checkins){
        for(let idx =0; idx <checkins.length;idx++){
          checkins[idx] = CheckIn.convertObject(checkins[idx])
        }
      }
      this.checkIns = checkins
      //console.log(this.checkIns)
    }).catch(err =>{
      console.log(err)
    })
    this.$forceUpdate()

    // for(let idx =0;idx < this.users.length;idx++){
    //   if(this.users[idx].id.trim() === this.dAppt.User.id.trim()){
    //     console.log("found one")
    //     console.log(this.dAppt.User.getFullName())
    //     if(this.$refs.users !== null) {
    //       this.$refs.users.selectedIndex = idx + 1
    //       console.log("idx")
    //       console.log(this.$refs.users.selectedIndex)
    //       //this.$refs.users.options.selectedIndex = idx +1
    //       //this.$refs.users.value = this.dAppt.User
    //     }
    //
    //
    //     break;
    //   }
    // }
  },
  data() {
    return {

      isOverride: false,
      showModal: false,
      months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

      years: this.getYears(),
      dAppt: this.appointment,
      errMessages: [],
      jobMessage: "Employee First",
      showMap: false,
      checkin: new CheckIn(),
      checkIns: null,
      place: "",
      employeeIdx: null,
      centerMeObj: {
        "col-12": this.disabled,
        "col-md-12": this.disabled,
        "col-sm-12": this.disabled,
        "col-xs-12": this.disabled,
        "text-center": this.disabled
      },


    }

  },

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    appointment: {
      type: Appointment,
      default: new Appointment(),
    },
    titleMsg: {
      type: String,
      default: "Add a Scheduled Event"
    },
    buttonMsg: {
      type: String,
      default: "Add"
    },
    triggerModal: {
      type: Boolean
    },
    momentDay: {
      type: Object,
      default: new moment().startOf("day")
    }
  },
  watch: {
    disabled: {
      deep: true,
      handler() {
        //console.log(this.disabled)
      }
    },
    users:{
      deep: true,
      handler() {
        // console.log("users changed")
        // console.log(this.users)
        // console.log(this.$refs.users.selectedIndex)
        this.dAppt.User = null;
        this.dAppt.User = this.users[this.$refs.users.selectedIndex -1]
      }

    },
    triggerModal: function (newVal) {
    //   console.log("new val:")
    // console.log(newVal)
      if (newVal) {
        this.setDAppt();

        this.openModal();
      }else{
        //this.dAppt.User = null
        this.setDAppt()
      }

    }
  },
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
    rota() {
      return this.$store.state.appointments;
    },
    users() {
      return this.$store.state.users;
    },
    userJobs() {
      let userJobs = this.dAppt.User.jobs;
      return Array.from(userJobs);
    },
    jobs(){
      return this.$store.state.jobs;
    },

    dateDay:function(){
      return this.appointment.duration.start.format("DD")
    },
    dateMonth:function(){
      return this.appointment.duration.start.format("MMM");
    },
    dateYear:function (){
      return this.appointment.duration.start.format("YYYY");
    },
    endDateDay:function(){
      return this.appointment.duration.end.format("DD")
    },
    endDateMonth:function(){
      return this.appointment.duration.end.format("MMM");
    },
    endDateYear:function (){
      return this.appointment.duration.end.format("YYYY");
    },
    startAMPM:function(){
      return this.appointment.duration.start.format("A");
    },
    startMin:function(){
      return this.appointment.duration.start.format("mm");
    },
    startHours: function(){

      return this.appointment.duration.start.format("hh")

    },

    endAMPM:function(){
      return this.appointment.duration.end.format("A");
    },
    endMin:function(){

      return this.appointment.duration.end.format("mm");
    },
    endHours: function(){
      return this.appointment.duration.end.format("hh")

    },

  },
  emits:["rotachange","showModal","deleterota"],

  methods: {
    handleOverride(){
      this.isOverride = true;
      if(!this.dAppt.isValidated){
        this.dAppt.isValidated = true
        this.dAppt.validateCheckin.licence = "NO Lat or lon"
      }
      else if(this.dAppt.validateCheckin.licence !== ""){
        this.dAppt.hasClockedOut = true;
      }
    },
    clockOutMessage(){
      console.log(this.dAppt)
      if( this.dAppt.hasClockedOut){
        return "clocked out"
      }else {
        return "clocked in"
      }
    },
    clockInMessage(){

      if(!this.dAppt.isValidated){
        return "Clock In"
      }else{
        return "Clock Out"
      }
    },
    getValidatedCheckingTime(){
     //console.log("validated time:")
     // console.log(this.dAppt.validateCheckin.time)
      if (typeof this.dAppt.validateCheckin.time === "string"){
        this.dAppt.validateCheckin.time = new moment(this.dAppt.validateCheckin.time)
      }
      return this.dAppt.validateCheckin.time.format("dddd, MMMM Do YYYY, h:mm:ss a")
    },
    mapAddress(){
      let addresses = this.$refs.addresses
      let address = this.$refs.address
      let mapper = this.$refs.mapper
     // console.log(typeof addresses.options[addresses.selectedIndex].value )
      //console.log(addresses.options[addresses.selectedIndex].value)
      //address.value = addresses.options[addresses.selectedIndex].value.trim()
      //alert(address.value)
      this.place = addresses.options[addresses.selectedIndex].value.trim()
      mapper.click()
      addresses.selectedIndex = 0
    },
    centerMe() {
    //  console.log(this.disabled);
      if (this.disabled) {
        return {
          "col-12": this.disabled,
          "col-md-12": this.disabled,
          "col-sm-12": this.disabled,
          "col-xs-12": this.disabled,
          "text-center": this.disabled
        };
      }
    },

    async validateRota(evt) {
      console.log("running validate rota")
      evt.stopImmediatePropagation();
      let osm = new OSMService();

      if(!this.dAppt.isValidated) {
        if (!this.dAppt.isBetweenDurationStartAndEnd(new moment())) {
          this.errMessages.push("You can't clock in unless the time now is between the start and end times")
          return
        }

        this.dAppt.validateCheckin = await osm.getByCoords();
        this.dAppt.validateCheckin.employee = this.loggedInUser;
        if (distanceTo({lat: parseFloat(this.dAppt.checkin.lat), lon: parseFloat(this.dAppt.checkin.lon)},
            {lat: parseFloat(this.dAppt.validateCheckin.lat), lon: parseFloat(this.dAppt.validateCheckin.lon)}) <= 500) {
          this.dAppt.isValidated = true;

        } else {
          this.errMessages.push("It appears that you are not at the correct place, you may need to call the office");
          return
        }
      }else{
        this.dAppt.clockOut = await osm.getByCoords();
        this.dAppt.clockOut.employee = this.loggedInUser
        if (distanceTo({lat: parseFloat(this.dAppt.checkin.lat), lon: parseFloat(this.dAppt.checkin.lon)},
            {lat: parseFloat(this.dAppt.clockOut.lat), lon: parseFloat(this.dAppt.clockOut.lon)}) <= 500) {
          this.dAppt.hasClockedOut = true;

        } else {
          this.errMessages.push("It appears that you are not at the correct place, you may need to call the office");
          return
        }
      }


      await this.addEditRota(evt);
    },

    async mapPlace() {

      let osm = new OSMService();
      this.checkin = await osm.getByAddress(this.place);
      if (this.checkin && this.checkin.lat !== "0") {
        this.checkin.display_name = this.place;
        // console.log("checkin place id")
        // console.log(this.checkin.place_id)
        let idx = this.checkIns.findIndex(elem =>{ return elem.place_id === this.checkin.place_id })

        if(idx === -1) {
          this.checkIns.push(this.checkin)
        }
        this.errMessages = []
      } else {
        this.errMessages.push("Sorry unable to find map for that address.")
      }
      //console.log(this.checkin)
      this.place = "";
      // console.log(parseFloat(this.checkin.lat));
      // console.log(parseFloat(this.checkin.lon));

    },
    getJobName(id) {
      //console.log("ID is: "+id)
      let idx = this.jobs.findIndex(elem => {
        return elem.id === id
      });
      if (idx >= 0) {
        return this.$store.state.jobs[idx].name;
      } else {
        return "(Deleted Job)";
      }
      //console.log("Index: "+ idx)

    },

    setUser(){
      // console.log("setting user:")
      this.employeeIdx = this.$refs.users.selectedIndex
      // console.log("employeeIdx")
      // console.log(this.employeeIdx)
      this.dAppt.User = null
      this.appointment.User = null
      this.dAppt.User = this.users[this.employeeIdx-1];
      this.appointment.User = this.users[this.employeeIdx-1];
      this.jobMessage = "Choose a Job";
    },
    isSameUserInSameTime() {
      let appt = this.dAppt;

      // determine that is is not the same rota, or a different rota with the same Employee and overlapping times
      function isSameUserInSameTime(elem) {
        return elem.id !== appt.id && elem.User.id === appt.User.id &&
            ((elem.duration.start > appt.duration.start && elem.duration.start < appt.duration.end) ||
                (elem.duration.end > appt.duration.start && elem.duration.end < appt.duration.end))
      }


      let idx = this.rota.findIndex(isSameUserInSameTime)


      return (idx > -1);

    },
    setDurations(){
      let startTime = {};
      startTime.hour = parseInt(this.$refs.startHour.options[this.$refs.startHour.selectedIndex].value);
      if(startTime.hour===12){
        startTime.hour = 0;
      }
      startTime.minute = parseInt(this.$refs.startMin.options[this.$refs.startMin.selectedIndex].value);
      let amPM = this.$refs.startAMPM.options[this.$refs.startAMPM.selectedIndex].value;
      if (amPM === 'PM') {
        startTime.hour = parseInt(startTime.hour) + 12;
      }
      startTime.month = this.$refs.dateMonth.selectedIndex;  /// month is index based
      ////////console.log(time.month);
      startTime.date = parseInt(this.$refs.dateDay.options[this.$refs.dateDay.selectedIndex].value);
      startTime.year = parseInt(this.$refs.dateYear.options[this.$refs.dateYear.selectedIndex].value);
      startTime.second = 0;
      startTime.millisecond = 0;
      startTime = new moment().set(startTime);
      //this.dAppt.duration.start.set(startTime);
      let endTime = {};
      endTime.hour = parseInt(this.$refs.endHours.options[this.$refs.endHours.selectedIndex].value);
      if (endTime.hour === 12) {
        endTime.hour = 0;
      }
      endTime.minute = parseInt(this.$refs.endMin.options[this.$refs.endMin.selectedIndex].value);
      let endAmPM = this.$refs.endAMPM.options[this.$refs.endAMPM.selectedIndex].value;
      if (endAmPM === 'PM') {
        //////console.log("It's PM")
        endTime.hour = parseInt(endTime.hour) + 12;
      }


      endTime.month = this.$refs.dateMonth.selectedIndex;  /// month is index based

      endTime.date = parseInt(this.$refs.dateDay.options[this.$refs.dateDay.selectedIndex].value);
      endTime.year = parseInt(this.$refs.dateYear.options[this.$refs.dateYear.selectedIndex].value);
      endTime.second = 0;
      endTime.millisecond = 0;
      endTime = new moment().set(endTime);


      this.dAppt.duration.start = startTime;
      this.dAppt.duration.end = endTime;
      return endTime.unix() > startTime.unix();

    },

    setDAppt(){
      this.dAppt = this.appointment;
      //console.log("appt")
      //console.log(this.dAppt)
      this.checkin = this.appointment.checkin;
    },
    getYears(){
      let yrs = []

      for(let i = -3;i < 3;i++){
        yrs.push(this.appointment.duration.start.clone().add(i,"year").format("YYYY"));
      }
      return yrs;
    },
    normalize(num){

      return num.toString().length ===2?num:"0"+num;
    },
    openModal() {
      // console.log("opening Modal");
      // console.log(this.appointment)
      if(this.appointment.id === this.appointment.DEFAULT_ID) {
        this.appointment.setDurationStart(this.momentDay);
        this.appointment.setDurationEnd(this.momentDay);
      }
      ////////console.log(this.appointment);
      this.showModal = true;
      document.querySelector("body").classList.add("overflow-hidden");

    },
    closeModal(evt) {
      ////console.log("closing modal ...")
      evt.stopImmediatePropagation();
      this.errMessages = [];
      this.showModal = false;
      this.place = "";
      this.checkin = new CheckIn();
      document.querySelector("body").classList.remove("overflow-hidden");
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit("showModal", false)

    },
    setUserJob(){
      let userJobSet = true;
      if(this.$refs.users.selectedIndex<0){
        this.errMessages.push("You must choose an Employee");
        userJobSet = false;
      }
      if(this.$refs.job.selectedIndex <0){
        this.errMessages.push("You must choose a Job");
        userJobSet = false;
      }
      if(userJobSet) {
        this.dAppt.User = this.users[this.$refs.users.selectedIndex - 1];
        this.dAppt.job = this.$refs.job.options[this.$refs.job.selectedIndex].value;
        this.errMessages = []
      }


      return userJobSet;

    },
    deleteRota(evt){
      evt.stopImmediatePropagation();
      //console.log("Rota modal emitting delete...")
      this.$emit("deleterota",this.dAppt)
      this.closeModal(evt);
    },
    async addEditRota(evt){
      console.log("running add/edit appt")
      evt.stopImmediatePropagation();
      if(!this.setUserJob()){
        return;
      }
      if(!this.setDurations()){
        this.errMessages.push("The end time must be later than the start time.")
        return;
      }

      if (this.isSameUserInSameTime()) {
        this.errMessages.push("That Employee is already scheduled in that time slot");
        return;
      }

      this.dAppt.setDurationInterval();// ok the data appt is finished
      this.checkin.employee = this.dAppt.User;
      if(this.dAppt.isValidated && (this.dAppt.clockOut.lon !== "0")){
        this.dAppt.hasClockedOut = true;
      }


      if(!this.dAppt.isValidated && (this.dAppt.validateCheckin.lon === "0" || this.dAppt.validateCheckin.lon === 0) && !this.isOverride)
      {
        console.log("creating or editing an appt")
        this.dAppt.checkin = this.checkin;
        this.dAppt.checkin.time = new moment()
        //this.dAppt.isValidated = true;
      }else if (this.dAppt.isValidated && !this.dAppt.hasClockedOut){
        console.log("Checking in")
        console.log(this.checkin)
        this.dAppt.validateCheckin = this.checkin
        this.dAppt.validateCheckin.time = new moment();
        this.dAppt.isValidated = true;
      }else {
        // clocking out
        console.log("clocking out")
        console.log(this.checkin)
        this.dAppt.clockOut = this.checkin;
        this.dAppt.clockOut.time = new moment();
      }

      this.$emit('rotachange', this.dAppt);

      //this.closeModal(evt);
    }
  },
 async created(){

  },

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.myModal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: lightyellow;
  border: black 2px solid;
  height: 90%;
}
.title{
  text-align:center;
  background-color: white;
  border: black 2px solid;
  padding-bottom: 10px ;
}
.errorContainer{
  color:red;

}
.myModal{
  overflow: scroll;
}

</style>
