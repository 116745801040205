<template>

  <canvas :ref="'c' +refNum" style="margin-bottom:0;padding-bottom: 0" >
    <slot :refNum="refNum"></slot>
  </canvas>


</template>

<script>
export default {
  data() {
    return {
      // By creating the provider in the data property, it becomes reactive,
      // so child components will update when `context` changes.
      provider: {
        // This is the CanvasRenderingContext that children will draw to.
        context: null
      }
    }
  },
  props:{
    refNum:{
      type:Number,
      default:0,
    },
  },

  // Allows any child component to `inject: ['provider']` and have access to it.
  provide () {
    return {
      provider: this.provider
    }
  },
  methods: {
      getRefNum(){
        //////console.log("RefNum: " + "c" +this.refNum)
        return "c" + this.refNum;
      }
  },
  mounted () {
    // We can't access the rendering context until the canvas is mounted to the DOM.
    // Once we have it, provide it to all child components.
    this.provider.context = this.$refs[this.getRefNum()].getContext('2d')

    //console.log("In canvas mounted")
    //console.log(this.provider.context)
    // Resize the canvas to fit its parent's width.
    // Normally you'd use a more flexible resize system.
    // this.$refs['my-canvas'].width = this.$refs['my-canvas'].parentElement.clientWidth
    // this.$refs['my-canvas'].height = this.$refs['my-canvas'].parentElement.clientHeight
    return 0;
  }
}
</script>
<style scoped>
canvas{
  pointer-events: none;
}
</style>
