const { NominatimJS } = require('@owsas/nominatim-js');
import {CheckIn} from "@/classes/CheckIn";
import axios from "axios";
const cross_fetch_1 = require("cross-fetch");





Object.defineProperty(exports, "__esModule", { value: true });


export class OSMService {
 // checkins = [];
  lat = 0;
  long = 0;

  constructor() {
    // "originalFetch" was registered here in this context
    //this.originalFetch = window.fetch
  }


  search(params) {
    params.format = params.format || 'json';
    if (params.countryCodesArray) {
      params.countrycodes = params.countryCodesArray.join(',');
    }
    if (params.accept_language) {
      params['accept-language'] = params.accept_language;
    }
    const url = new URL('https://nominatim.openstreetmap.org/search');
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    //console.log(url)
    return fetch(url)
        .then(res => res.json());
  }

  async getByAddress(address) {
    let response
    try {
      response = await this.search({
        q: address
      })
    } catch (e) {
      console.log(e)
    }
   // console.log(response)
    let checkIn;
    if (response !== null && typeof response !=='undefined' && response.length !==0) {
      console.log("OSM response")
      console.log(response[0])
      checkIn = CheckIn.convertObject(response[0]);
      checkIn.license = response[0].license
      checkIn.lat = parseFloat(response[0].lat)
      checkIn.lon = parseFloat(response[0].lon)
      checkIn.place_id = response[0].place_id.toString()
    } else {
      checkIn = new CheckIn();

      let url = "https://api.mapbox.com/search/geocode/v6/forward?q=";
      url = url + address + "&access_token=pk.eyJ1IjoibWNsb3ZpczE5NTkiLCJhIjoiY2ttZmhwaWltMHpzcjJ2cDI2OTIyMXR5eCJ9.F9YQyjkfpucNbSYfScmTuw";

      await axios.get(url).then(response => {
        let features = response.data.features;

        for (let idx = 0; idx < features.length; idx++) {

          if (features[idx].properties.feature_type.startsWith("address")) {

            checkIn.lon = features[idx].properties.coordinates.longitude
            checkIn.lat = features[idx].properties.coordinates.latitude;
            checkIn.display_name = address;
            checkIn.place_id = features[idx].properties.mapbox_id
            checkIn.license = response.data.attribution
            break;
          }

        }
        // console.log("checkin")
        // console.log(checkIn)

      }).catch(err => {
        console.log(err);
      })
    }
    return checkIn;


  }

  async getByCoords(lat, long) {
    let url = "https://nominatim.openstreetmap.org/reverse?lat=";
    if (lat && long) {
      url = url + lat + "&lon=" + long + "&format=json";
    } else {
      try {
        let location = await this.getLatAndLong();
        url = url + location.coords.latitude + "&lon=" + location.coords.longitude + "&format=json";
      } catch (e) {
        return new CheckIn();
      }

    }
    let checkIn = new CheckIn();
    await axios.get(url).then(response => {
      let result = response.data;
      checkIn = CheckIn.convertObject(result);
    }).catch(err => {
      console.log(err);
      checkIn = new CheckIn();
    })
    return checkIn;
  }

  async getLatAndLong() {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        }, {enableHighAccuracy: true});
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve(null)
      }, err => {
        reject(err);
      });
    }
    //return  window.navigator.geolocation.getCurrentPosition(async (pos) =>{await this.success(pos,this)}, error, options);
  }

}
