export class Rectangle{
  ID = 0;
  constructor(topY,bottomY,leftX,rightX) {
    this.topY = topY;
    this.bottomY = bottomY;
    this.leftX = leftX;
    this.rightX = rightX;
  }
  setID(ID){
    this.ID = ID;
  }
  isInsideRect(clientX,clientY){
    return (clientX >= this.leftX && clientX <= this.rightX) &&
        (clientY <= this.bottomY && clientY >= this.topY)
  }
}
