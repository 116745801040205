<template>
  <div>
  <!-- Modal section -->
  <RotaModal :disabled="disabled" :moment-day="moment" :appointment="appointment" :button-msg="buttonMsg"
             :title-msg="titleMsg" :trigger-modal="false" @rotachange="rotaChange" @showModal="changeShow"
             @deleterota="deleteRota"></RotaModal>
  <!-- end Modal section -->
  <div class="square" @click="openModal" >

    <span v-if="moment.toString() === today.toString()" style="color:#ff0000">{{ moment.format("dd Do") }}</span>
    <span v-else>{{moment.format("dd Do")}}</span>
    <my-canvas width="100%" height="100%" :refNum="refNum"  >

      <Rota @rotaChosen="showRota" ref="rotas"  :rotas="arrayRotas" :ref-num="refNum"  ></Rota>

    </my-canvas>

  </div>
  </div>
</template>

<script>
import Rota from "./Rota";
import MyCanvas from "./MyCanvas";
import RotaModal from "./RotaModal";
import {Appointment} from "../classes/Appointment";

export default {
  name: "Day",
  components: {Rota,MyCanvas,RotaModal},
  data() {
    return {
      arrayRotas: [],
      buttonMsg: "",
      titleMsg: "",
      show: false,
      appointment: new Appointment(),
      rota: {},
      disabled: false,
    }
  },
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    }
  },
  props: {
    today: {
      type: Object,
      required: true,
    },
    rotas: {
      type: Array,
      required: true
    },
    refNum: {
      type:Number,
      default:0,
    },

    moment:{
      type:Object,
      required:true,
    }

  },
  watch:{
    moment:{
      deep:true,
      handler(){
        //////console.log("MOMENT changed");
      }
    },
    rotas:{
      deep:true,
      handler(){
        //////console.log("rotas changed on day");
       this.fixRotas();
      }
    }
  },
  mounted() {

    this.rota = this.$refs.rotas;
    this.fixRotas();
    ////////console.log("for "+this.moment.format("Do") + " len is "+ this.arrayRotas.length);
  },
  unmounted() {
    //window.removeEventListener("resize",this.redrawRota)
  },
  emits:["rotachange","deleterota"],
  methods:{
    jobs(){
      return this.$store.state.jobs
    },
    openModal(evt) {
      //console.log("opening modal");
      if (!this.show) {
        this.rota.onMouseDown(evt);
      }
    },
    rotaChange(arg){
      this.$emit("rotachange",arg); // emit to home
    },
    deleteRota(arg){
      //console.log("Day emitting delete ..")
      this.$emit("deleterota",arg); // emit to home
    },
    changeShow(arg){
      ////console.log("should change show..");
      this.show = arg;
      this.rota.showModal = this.show;
    },

    changeAppt(arg){
      this.appointment = arg;
    },
    showRota(arg) {

      this.appointment = arg;

      if (arg.id !== this.appointment.DEFAULT_ID) {
        if (this.loggedInUser.isAdmin) {
          this.titleMsg = "Edit The Schedule Item";
          this.buttonMsg = "Edit";
          this.show = true;
          this.disabled = false;
        } else {
          this.titleMsg = "Check-In For Scheduled Item";
          this.buttonMsg = "Check-In";
          this.show = true;
          this.disabled = true;
        }
      } else {
        if (this.loggedInUser.isAdmin) {
          this.titleMsg = "Create a new Schedule Item";
          this.buttonMsg = "Add";
          this.show = true;
          this.disabled = false;
        }
      }

    },
    fixRotas(){
      //////console.log("fixing rotas")
      this.arrayRotas = [];
      let arr = this.rotas;

      while (arr.length >0){
        let oneDimArray = [];
        let rota = arr[0];
        let isBetweenMoments = (elem) => (elem).isBetween(rota.duration.start,rota.duration.end)
        let idx = arr.findIndex(isBetweenMoments);
        ////////console.log(idx);
        while(idx >=0){

          let r = arr.splice(idx,1)

          //oneDimArray = oneDimArray.concat(r);
          oneDimArray = oneDimArray.concat(r);
          idx = arr.findIndex(isBetweenMoments);
        }

        this.arrayRotas.push(oneDimArray);
      }
      //////console.log("finished fixing rotas...")



    }
  }
}
</script>

<style scoped>

.square{

  height: 0;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: #B6E5D8;
  border: black 3px solid;

}

</style>
