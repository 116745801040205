

<script>
import {Appointment} from "../classes/Appointment";
import {Rectangle} from "../classes/Rectangle";

export default {
  name: "Rota",
  inject: ['provider'],
  props:{
    rotas: {
      type:Array,
      required:true
    },
    refNum:{
      type:Number,
      default:0,
    },


  },
  data() {

    let boxes = [];
    let colors = ["red","yellow","green","pink","darkblue"];
    let canvas = {};
    let ctx = {};
    let canvasBox = {};

    return {

      boxes,
      colors,
      canvas,
      ctx,
      canvasBox,

    }
  },
  emits: ['rotaChosen'],
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
    jobs(){
      return this.$store.state.jobs
    }
  },
  methods: {

    setCanvas() {
      if (!this.provider.context) return;
      this.ctx = this.provider.context;
      this.canvas = this.ctx.canvas;
    },
    disableContextMenu(evt) {
      evt.preventDefault();
      return false;
    },
    drawRota() {
    // console.log("drawing rota...")
      this.setCanvas();
      if (!this.provider.context) return;

      this.boxes = [];


      let scale = window.devicePixelRatio||1; // Change to 1 on retina screens to see blurry canvas.

      //console.log("scale: "+ scale);

// Normalize coordinate system to use css pixels.
      this.ctx.scale(scale, scale);




      let parentSize = this.canvas.getBoundingClientRect();
      parentSize.parent = this.canvas.parentElement.getBoundingClientRect();
      //////console.log("for refnum "+ this.refNum);
     //////console.log(parentSize.parent)

      //parentSize.parent.width = parentSize.parent.width ;
      //parentSize.parent.height = parentSize.parent.height / scale;


      // calculate the pixes per half our of schedule
      let pHH = Math.floor(parentSize.parent.height / 48) -1;
      //clear the rectangle plus some to ensure "blank" canvas
      //this.ctx.clearRect(0, 0, parentSize.parent.width + 1000, parentSize.parent.height + 1000);

      // set the canvas height and width
      this.canvas.style.width = parentSize.parent.width + "px";
      this.canvas.style.height = parentSize.parent.height +"px";



      this.canvas.height = parentSize.parent.height;
      this.canvas.width = parentSize.parent.width;
      // get canvas size
      this.canvasBox = new Rectangle(parentSize.y,parentSize.y +parentSize.parent.height,parentSize.x,parentSize.x + parentSize.parent.width);
      ////////console.log(this.canvasBox);
      for(let arrIdx = 0;arrIdx < this.rotas.length;arrIdx++){


        for (let idx = 0; idx < this.rotas[arrIdx].length; idx++) {

          if (this.loggedInUser.isAdmin || this.rotas[arrIdx][idx].User.id === this.loggedInUser.id) {
            //console.log(this.jobs.length)
            for (let jIdx = 0; jIdx < this.jobs.length; jIdx++){
              ////console.log("first job....")
           // console.log(this.rotas[arrIdx][idx].job)
            //console.log(this.jobs[jIdx].name)
              if (this.rotas[arrIdx][idx].job === this.jobs[jIdx].name) {

                //console.log("job....")
               // console.log(this.rotas[arrIdx][idx].job)
                //console.log(this.jobs[jIdx])
                // calculate the height (length of scheduled event)
                let rotaHeight = pHH * this.rotas[arrIdx][idx].halfHours;
                let topOffSet = pHH * this.rotas[arrIdx][idx].startHalfHours;

                let leftX = this.canvas.offsetLeft + 5 + parentSize.parent.width * (idx / this.rotas[arrIdx].length);
                let topY = this.canvas.offsetTop + topOffSet - 5;
                let rectWidth = parentSize.parent.width * (1 / this.rotas[arrIdx].length) - 15;
                let rect = new Rectangle(topY, topY + rotaHeight, leftX, leftX + rectWidth);
//          ////console.log(this.rotas[arrIdx][idx])
                rect.setID(this.rotas[arrIdx][idx].id);

                // this.boxes.splice(arrIdx, 0, rect);
                this.boxes.push(rect);


// Set actual size in memory (scaled to account for extra pixel density).
                //let scale = 1;

                this.ctx.beginPath();
                //ctx.fillStyle = "red";

                //console.log("jIdx")
               // console.log(jIdx +1)
                //console.log("remainder")
                //console.log((jIdx +1)% 4)
                this.ctx.fillStyle = this.colors[(jIdx+1) % this.jobs.length]

                this.ctx.rect(leftX, topY, rectWidth, rotaHeight);


                this.ctx.fill();
                this.ctx.stroke();

                this.ctx.fillStyle = "#000000";

                this.ctx.font = pHH + "px Arial";
                let text = "";
                switch (this.rotas[arrIdx][idx].halfHours) {
                  case 1:
                    text = this.rotas[arrIdx][idx].getUserFullName() + " --- " +
                        this.rotas[arrIdx][idx].job + this.rotas[arrIdx][idx].getDurationStart() +
                        " - " + this.rotas[arrIdx][idx].getDurationEnd();
                    break;
                  case 2:
                  case 3:
                    text = this.rotas[arrIdx][idx].getUserFullName() + " --- " +
                        this.rotas[arrIdx][idx].job + "\n" + this.rotas[arrIdx][idx].getDurationStart() +
                        " - " + this.rotas[arrIdx][idx].getDurationEnd();
                    break;
                  default:
                    text = this.rotas[arrIdx][idx].getUserFullName() + "\n";
                    text = text + this.rotas[arrIdx][idx].job + "\n";
                    text = text + this.rotas[arrIdx][idx].getDurationStart() + "\n";
                    text = text + this.rotas[arrIdx][idx].getDurationEnd()


                }

                let lines = text.split("\n");
                for (let idx = 0; idx < lines.length; idx++) {
                  this.ctx.fillText(lines[idx], leftX, topY + pHH + (pHH * idx));
                }

                //this.ctx.fillText(text, leftX, topY + pHH);

                this.ctx.stroke();
              }

          }
        }
        }
        // end inner for loop
      }
      // try to force "redraw"
      this.canvas.style.display = "none";
      this.canvas.style.display = "block";
      return this.refNum;

    },
    onMouseDown(evt){

      if (!this.showModal ) {
        let rect = this.canvas.parentElement.getBoundingClientRect();

        let isInRectangle = (elem) => elem.isInsideRect(evt.clientX - rect.left, evt.clientY - rect.top);

        let idx = this.boxes.findIndex(isInRectangle);
      //  ////console.log(idx);
        if (idx >= 0) {
          //////console.log("Inside");
          let ID = this.boxes[idx].ID;
          //////console.log(ID);
          foundRota:
              for (let i = 0; i < this.rotas.length; i++) {
                for (let j = 0; j < this.rotas[i].length; j++) {
                  if (this.rotas[i][j].id === ID) {
                    // //////console.log("emitting")
                    // eslint-disable-next-line vue/custom-event-name-casing
                    this.$emit("rotaChosen", this.rotas[i][j]);
                    break foundRota;
                  }
                }
              }

        } else if (this.canvasBox.isInsideRect(evt.clientX , evt.clientY )) {
          //console.log("outside");
          // eslint-disable-next-line vue/custom-event-name-casing
          this.$emit("rotaChosen", new Appointment());
          //break foundRota;

        } else {
          //console.log("confused");
          //////console.log(evt.clientX, evt.clientY);
          // eslint-disable-next-line vue/custom-event-name-casing
          this.$emit("rotaChosen", new Appointment()); // emit anyway
        }
      }
      return false;
    },
  },
  watch:{
    rotas:function(){
      //this.drawRota();
    }
  },
 mounted() {
    //console.log("in mounted")
    this.drawRota();
 },

  render() {

    window.addEventListener('resize', this.drawRota);

    window.addEventListener("contextmenu", this.disableContextMenu);

    return this.drawRota();


  },


  unmounted() {
    window.removeEventListener("contextmenu", this.disableContextMenu);
    window.removeEventListener('resize', this.drawRota)

  }
}
</script>

<style scoped>


</style>
