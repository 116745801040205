import moment from "moment";

export class TimeCard{
    startTime = null
    endTime = null
    verifiedCheckIn = null


    constructor(verifiedCheckIn,clockOut) {
        if (verifiedCheckIn) {
            this.startTime = new moment(verifiedCheckIn.time)
            this.verifiedCheckIn = verifiedCheckIn
        }
        if (clockOut) {
            this.endTime = new moment(clockOut.time)
        }
    }
    format = (num, decimals) => num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    getHoursWorked(){
        // console.log("get hours worked")
        // console.log(this.startTime)
        // console.log(this.endTime)
        // console.log(this.startTime !== null)
        // console.log(this.endTime !== null)
        if (this.startTime !== null && this.endTime !==null && this.verifiedCheckIn.employee.lName !== "" ){
            let ans = Math.round(this.endTime.diff(this.startTime,"hours",true) *100)/100
            if (ans > 0.01){
                return ans
            }else {
                return 0.00
            }
        }else{
            return 0.00
        }
    }

}